<template>
  <b-container id="saml-connection-new" class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h3>New SAML Connection</h3>
        <router-link :to="{ name: 'SamlCoreConnectionList' }" class="d-flex justify-content-left align-items-center">
          <b-icon icon="arrow-left" aria-label="Back to connection list"/>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-form-group
          label="Connection Key"
          description="Unique key to identify the Connection"
          label-for="connection-key"
          invalid-feedback="Invalid key"
          :state="connectionKeyValidationState">
          <b-form-input
            id="connection-key" type="text" placeholder="Enter connection key"
            :state="connectionKeyValidationState" v-model="data.key"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Partner Key"
          description="Unique key to identify the Partner"
          label-for="partner-key"
          invalid-feedback="Invalid key"
          :state="partnerKeyValidationState">
          <b-form-select
            id="partner-key"
            v-model="data.core_partner_key"
            :state="partnerKeyValidationState"
            :options="partners"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Active"
          description="Enable or disable the connection"
          label-for="is-active">
          <b-form-checkbox
            id="is-active"
            v-model="data.is_active"
            name="is-active"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Configuration">
          <b-row>
            <b-col cols="3">
              <b-form-group>
                <b-form-radio-group
                  v-model="toggles.configuration"
                  :options="configurationTypeOptions"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="isConfigurationCore" cols="3">
              <b-form-group
                label="Configuration"
                :state="configurationKeyValidationState"
                invalid-feedback="Invalid configuration">
                <b-form-select
                  v-model="data.core_configuration_uuid"
                  :state="configurationKeyValidationState"
                  :options="connections"/>
              </b-form-group>
            </b-col>
            <template v-if="isConfigurationCertificates">
              <b-col cols="3">
                <b-form-group
                  label="Public Key"
                  :state="configurationCertificatesPublicValidationState"
                  invalid-feedback="Invalid certificate">
                  <b-form-file
                    id="public-key" drop-placeholder="Choose a file or drop it here..."
                    :state="configurationCertificatesPublicValidationState" v-model="data.configuration_public_key"/>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="Private Key"
                  :state="configurationCertificatesPrivateValidationState"
                  invalid-feedback="Invalid key">
                  <b-form-file
                    id="private-key" drop-placeholder="Choose a file or drop it here..."
                    :state="configurationCertificatesPrivateValidationState" v-model="data.configuration_private_key"/>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card title="IdP Metadata">
          <b-row>
            <b-col cols="3">
              <b-form-group>
                <b-form-radio-group
                  v-model="toggles.metadata"
                  :options="metadataTypeOptions"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-form-group
                v-if="isIdpMetadataUrl"
                :state="idpMetadataUrlValidationState"
                invalid-feedback="Invalid URL">
                <b-form-input
                  id="metadata-url" type="text" placeholder="Enter IdP Metadata URL"
                  :state="idpMetadataUrlValidationState" v-model="data.idp_metadata_url"/>
              </b-form-group>
              <b-form-group
                v-if="isIdpMetadataContent"
                :state="idpMetadataContentValidationState"
                invalid-feedback="Invalid file">
                <b-form-file
                  id="metadata-file" drop-placeholder="Choose a file or drop it here..."
                  :state="idpMetadataContentValidationState" v-model="data.idp_metadata_content"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="4" class="d-flex justify-content-left align-items-center">
        <b-button variant="primary" @click="create" :disabled="creating || isFormInvalid">
          <b-spinner small v-if="creating"></b-spinner>
          <span v-else>Save</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { required, url, helpers } from 'vuelidate/lib/validators';
import { list as listConfigurations } from '@/api/saml/core/configurations';
import { create } from '@/api/saml/core/connections';
import { list as listPartners } from '@/api/saml/core/partners';

const keyValidator = helpers.regex('partnerKey', /^[a-z0-9_]{3,64}$/);

const ConfigurationTypeCore = 'core';
const ConfigurationTypeCertificates = 'certificates';
const MetadataTypeURL = 'url';
const MetadataTypeContent = 'content';

export default {
  name: 'SamlCoreConnectionNew',
  beforeMount() {
    this.loading = true;

    Promise.allSettled([ listPartners(), listConfigurations() ])
      .then(([ listPartnersResult, listConfigurationsResult ]) => {
        if (listPartnersResult.status === 'rejected') {
          Vue.prototype.$noty.error(`Failed to list partners: ${listPartnersResult.reason}`);
        } else {
          const data = listPartnersResult.value;
          for (let i = 0; i < data.length; i++) {
            this.partners.push({
              text: data[i].key,
              value: data[i].key,
            });
          }
        }

        if (listConfigurationsResult.status === 'rejected') {
          Vue.prototype.$noty.error(`Failed to list configurations: ${listConfigurationsResult.reason}`);
        } else {
          const data = listConfigurationsResult.value;
          for (let i = 0; i < data.length; i++) {
            this.connections.push({
              text: data[i].name,
              value: data[i].uuid,
            });
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      data: {
        key: '',
        core_partner_key: null,
        core_configuration_uuid: null,
        configuration_public_key: '',
        configuration_private_key: '',
        idp_metadata_url: '',
        idp_metadata_content: '',
        is_active: true,
      },
      toggles: {
        configuration: ConfigurationTypeCore,
        metadata: MetadataTypeURL,
      },
      configurationTypeOptions: [
        { text: 'Core Configuration', value: ConfigurationTypeCore },
        { text: 'Certificates', value: ConfigurationTypeCertificates },
      ],
      metadataTypeOptions: [
        { text: 'URL', value: MetadataTypeURL },
        { text: 'File', value: MetadataTypeContent },
      ],
      partners: [
        { value: null, text: 'Please select a Partner' },
      ],
      connections: [
        { value: null, text: 'Please select a Configuration' },
      ],
      creating: false,
      loading: false,
    };
  },
  validations() {
    return {
      data: {
        key: {
          required,
          keyValidator,
        },
        core_partner_key: {
          required,
          keyValidator,
        },
        core_configuration_uuid: {
          required,
        },
        configuration_public_key: {
          required,
        },
        configuration_private_key: {
          required,
        },
        idp_metadata_url: {
          required,
          url,
        },
        idp_metadata_content: {
          required,
        },
      },
    };
  },
  computed: {
    connectionKeyValidationState() {
      return !this.$v.data.key.$invalid;
    },
    partnerKeyValidationState() {
      return !this.$v.data.core_partner_key.$invalid;
    },
    isConfigurationCore() {
      return this.toggles.configuration === ConfigurationTypeCore;
    },
    configurationKeyValidationState() {
      return !this.$v.data.core_configuration_uuid.$invalid;
    },
    isConfigurationCertificates() {
      return this.toggles.configuration === ConfigurationTypeCertificates;
    },
    configurationCertificatesPublicValidationState() {
      return !(this.isConfigurationCertificates && this.$v.data.configuration_public_key.$invalid);
    },
    configurationCertificatesPrivateValidationState() {
      return !(this.isConfigurationCertificates && this.$v.data.configuration_private_key.$invalid);
    },
    isIdpMetadataUrl() {
      return this.toggles.metadata === MetadataTypeURL;
    },
    idpMetadataUrlValidationState() {
      return !(this.isIdpMetadataUrl && this.$v.data.idp_metadata_url.$invalid);
    },
    isIdpMetadataContent() {
      return this.toggles.metadata === MetadataTypeContent;
    },
    idpMetadataContentValidationState() {
      return !(this.isIdpMetadataContent && this.$v.data.idp_metadata_content.$invalid);
    },
    isFormInvalid() {
      let ok = this.connectionKeyValidationState && this.partnerKeyValidationState;

      if (this.isConfigurationCore) {
        ok = ok && this.configurationKeyValidationState;
      } else {
        ok = ok && this.configurationCertificatesPublicValidationState && this.configurationCertificatesPrivateValidationState;
      }

      if (this.isIdpMetadataUrl) {
        ok = ok && this.idpMetadataUrlValidationState;
      } else {
        ok = ok && this.idpMetadataContentValidationState;
      }

      return !ok;
    },
  },
  methods: {
    create() {
      this.creating = true;

      const data = new FormData();
      data.append('key', this.data.key);
      data.append('core_partner_key', this.data.core_partner_key);
      data.append('is_active', this.data.is_active);

      if (this.isConfigurationCore) {
        data.append('core_configuration_uuid', this.data.core_configuration_uuid);
      } else {
        data.append('public_key', this.data.configuration_public_key);
        data.append('private_key', this.data.configuration_private_key);
      }

      if (this.isIdpMetadataUrl) {
        data.append('idp_metadata_url', this.data.idp_metadata_url);
      } else {
        data.append('idp_metadata_content', this.data.idp_metadata_content);
      }

      create(data)
        .then(key => {
          this.$router.push({ name: 'SamlCoreConnectionView', params: { key } });
        })
        .catch(err => {
          Vue.prototype.$noty.error(`Failed to create connection: ${err}`);
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
